<template lang="pug">
  #header-navigation-bar
    //- Navigation bar for main view START
    v-app-bar(
      v-if="mainView"
      app
      height="150"
      hide-on-scroll
    )
      v-col.navigation-bar(xl="2" lg="2" md="2")
        router-link(to="/")
          img.brand-logo(
            src="@/assets/images/icon/logo.png"
          )
      v-col.navigation-bar(xl="8" lg="8" md="7")
        //- HomePage button
        router-link(to="/" class="navigation-buttons")
          b KNOW YOUR V
        span |
        router-link(to="/quiz" class="navigation-buttons")
          b {{$t("menu.quiz") }}
        span |
        //- router-link(to="/competition" class="navigation-buttons")
        //-   b {{$t("menu.competition") }}
        //- span |
        //- router-link(to="/moment" class="navigation-buttons")
        //-   b #MOMENTSBERSAMAIBU
        //- span |
        router-link(to="/survey" class="navigation-buttons")
          b {{$t("menu.mom") }}
        span |
        router-link(to="/contact" class="navigation-buttons")
          b {{$t("menu.contact") }}
      v-col.navigation-bar(xl="2" lg="2" md="3" class="px-0 d-flex")
        v-btn(
          v-if="isLoggedIn"
          depressed
          color="primary"
          to="/profile"
        ) 
          b {{ $t("menu.profile.text") }}
        v-btn(
          v-if="!isLoggedIn"
          depressed
          color="primary"
          to="/register"
        ) 
          b {{ $t("menu.register.text") }}
        a(
          @click="changeLocale('en')"
        ) {{ $t("menu.language.malay.text") }}
        span.mx-1 |
        a(
          @click="changeLocale('zh')"
        ) {{ $t("menu.language.mandarin.text") }}
        div.mx-1

    //- Navigation bar for alternate view START
    v-app-bar(
      v-else-if="alternateView"
      app
      color="white"
      dense
      dark
      hide-on-scroll
      height="150"
    )
      router-link.mr-auto(to="/")
        img.brand-logo(
          width="120"
          src="@/assets/images/icon/logo.png"
        )
      v-btn(
        v-if="isLoggedIn"
        depressed
        color="primary"
        small
        to="/profile"
      ) 
        b(style="color: white") {{ $t("menu.profile.text") }}
      v-btn(
        v-if="!isLoggedIn"
        depressed
        color="primary"
        small
        to="/register"
      ) 
        b(style="color: white") {{ $t("menu.register.text") }}
      v-btn(
        class="menu"
        icon 
        small 
        @click.stop="alternateDrawer = !alternateDrawer"
      )
        v-icon(color="black") mdi-menu
      a(
        @click="changeLocale('en')"
      ) {{ $t("menu.language.malay.text") }}
      span.mx-1 |
      a(
        @click="changeLocale('zh')"
      ) {{ $t("menu.language.mandarin.text") }}
    v-navigation-drawer.navigation-drawer(
      v-model="alternateDrawer"
      fixed
    )
      v-list-group(:value="true" color="#006994")
        v-list-item
          router-link(to="/" class="navigation-buttons")
            b KNOW YOUR V
        v-divider
        v-list-item
          router-link(to="/quiz" class="navigation-buttons")
            b {{$t("menu.quiz") }}
        v-divider
        v-list-item
          router-link(to="/competition" class="navigation-buttons")
            b {{$t("menu.competition") }}
        v-divider
        //- v-list-item
        //-   router-link(to="/moment" class="navigation-buttons")
        //-     b #MOMENTSBERSAMAIBU
        //- v-divider
        v-list-item
          router-link(to="/survey" class="navigation-buttons")
            b {{$t("menu.mom") }}
        v-divider
        v-list-item
          router-link(to="/contact" class="navigation-buttons")
            b {{$t("menu.contact") }}
      v-container.button-container

        //- Navigation bar for alternate view END
</template>

<script>
import config from "@/config";
import errorTags from "@/errorTags";

export default {
  data() {
    return {
      alternateDrawer: false,
      loading: false,
      feedback: {
        success: false,
        status: errorTags.request.failed
      }
    };
  },
  computed: {
    mainView() {
      return (
        this.$vuetify.breakpoint.lg ||
        this.$vuetify.breakpoint.xl ||
        this.$vuetify.breakpoint.md
      );
    },
    alternateView() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    }
  },
  methods: {
    changeLocale(locale) {
      this.$i18n.locale = locale;
      this.$store.commit("customer/selectLocale", locale);
    }
  }
};
</script>

<style lang="sass" scoped>
@import '~vuetify/src/styles/styles.sass'

@media #{map-get($display-breakpoints, 'sm-and-down')}
  .v-toolbar
    background: transparent !important
    .v-toolbar__content
      // left: 0 !important
      // width: 100vw !important
      background: transparent !important
      box-shadow: unset !important

    .brand-logo
      transform: scale(1) !important
      margin: 0 !important
      margin-right: auto !important

    .v-btn
      margin: auto .2rem
      // font-size: .8vw
      background: $primary-color
      border-radius: 5px

      &.menu
        margin-right: 1rem
        background: white
        i
          color: $primary-color !important

    a,span
      color: $secondary-color !important
      margin: auto 0
</style>
