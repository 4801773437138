<template lang="pug">
  v-app#top
    Header
    .right-button
      a(href="https://libressemy.com/wow/" target="_blank") Virtual Discovery
    router-view#main-page
</template>

<script>
import config from "@/config";
import errorTags from "@/errorTags";

import Header from "@/views/home/Header";
// import Footer from "@/views/home/Footer";

export default {
  data() {
    return {
      loading: false,
      feedback: {
        success: false,
        status: errorTags.request.failed
      }
    };
  },
  watch: {
    ["$route"]() {
      window.scrollTo(0, 0);
    }
  },
  components: {
    Header,
    // Footer
  },
  mounted() {
    this.$i18n.locale = this.$store.state.customer.locale;
  },
  computed: {
    isLanding() {
      return this.$route.name == "Landing" ? true : false;
    },
    loginPath() {
      return "/login";
    }
  },
  methods: {}
};
</script>

<style lang="sass" scoped>
.right-button
  z-index: 9999
  cursor: pointer
  border-radius: 0 10px 10px 0
  text-align: center
  position: fixed
  right: 0
  top: calc(50vh - 50px)
  height: 150px
  writing-mode: tb-rl
  transform: rotate(-180deg)
  padding: 1rem .25rem
  background: $primary-color
  a
    color: white
    font-weight: bolder
</style>
